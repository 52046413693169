import React, {useRef, useState} from 'react'

const Login = (props) => {

  const phoneInput = useRef(null)
  const passwordInput = useRef(null)

  const [warning, setWarning] = useState(null)

  const checkLogin = async () => {
    let phone = phoneInput.current.value?.match(/\d/g)?.join("")
    let password = passwordInput.current.value?.replace()

    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone, password })
    }
    let status
    let result = await fetch(`${process.env.REACT_APP_USER_API}/auth/login`, options)
      .then( response => {
        status = response.ok
        return response.json()
      } )
      .catch( err => void 0 )
    if (status) {
      localStorage.setItem('currentUserToken', result.token);
      localStorage.setItem('currentUserName', result.name);
      localStorage.setItem('currentUserPhone', result.phone);
      window.location.reload(true)
    } else {
      console.log(result)
      setWarning(result?.error?.message)
    }
  }

  const keyHandler = (e) => {
    if (e.which === 13) {
      checkLogin().then(null)
    }
  }

  return(
    <div className="globalWrapper mainWrapper">
      <div className="mainContainer">
        <div className="userBar">
          <p className="loginBlockTitle">Ласкаво просимо!</p>
        </div>
        <div className="loginBlock">
          <p className="loginBlockParagraph">Введіть номер телефону:</p>
          <input ref={phoneInput}
                 placeholder="099 123 45 67"
                 type="text"
                 // value={mask}
                 // onKeyDown={maskHandler}
                 onChange={ () => void 0 }
                 className="loginInput"
                 onKeyPress={ (e) => keyHandler(e) }/>

          <p className="loginBlockParagraph">Введіть пароль:</p>
          <input className="loginInput"
                 placeholder="************"
                 onKeyPress={ (event) => {keyHandler(event)} }
                 ref={passwordInput}
                 type="password"/>
          <button className="loginButton" onClick={ checkLogin }>Увійти</button>
          { warning && <p className="warning">{warning}</p> }
        </div>
      </div>
    </div>
  )
}

export default Login